<template>
  <div class="pt-2 pl-3 pr-3">
    <div class="heading-text">Login to WizFit Challange Admin Dashboard</div>
    <div class="subHeading-text my-5">
      Welcome back! Please login to your account.
    </div>
    <v-form ref="loginForm" lazy-validation>
      <v-row no-gutters class="">
        <v-col xs="8" class="">
          <v-text-field
            outlined
            label="Email ID"
            :rules="[rules.required, rules.email]"
            v-model="loginForm.identifier"
            class="textField-LoginField"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="">
        <v-col xs="8" class="">
          <v-text-field
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            outlined
            label="Enter Password"
            class="textField-LoginField"
            :rules="[rules.required]"
            v-model="loginForm.password"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            @keyup.enter="submitLoginForm"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn
        block
        dark
        class="login-btn py-7 rounded-lg"
        style=""
        color="#D30024"
        @click="submitLoginForm"
        ref="submitBtn"
        :loading="loginForm.submitting"
      >
        <span class="text-loginBtn">Login</span>
        <v-icon right size="30" black> mdi-chevron-right </v-icon>
      </v-btn>
      <div class="forgotPassword-redirect text-right d-flex justify-end mt-5">
        <div class="align-self-center">Forgot Password?</div>
        <v-btn class="resetNow-btn pa-3" small text to="/forgot-password"
          >Reset Now</v-btn
        >
      </div>
    </v-form>
  </div>
</template>

<script>
import RULES from "@/common/fieldRules";
import { API_COMMON_URL, ROUTER_URL } from "@/constants/urls";
import Axios from "@/api/BaseAxios";
import authToken from "@/common/authToken";
import Keys from "@/constants/keys";
import Helper from "@/helper";
import { mapActions } from 'vuex';
export default {
  name: "LoginPage",
  components: {},
  data() {
    return {
      showPassword: false,
      rules: RULES,
      loading: false,
      loginForm: {
        identifier: "",
        password: "",
        submitted: false,
        submitting: false,
      },
    };
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    ...mapActions({
      showToast: 'snackBar/showToast'
    }),
    /**
     * Form submit handler
     * @param e
     */
    submitLoginForm() {
      if (this.$refs.loginForm.validate()) {
        const self = this;
        self.loginForm.submitting = true;
        /**
         * Success handler of API call
         */
        const successHandler = (res) => {
           
          let data = res.data;
          localStorage.setItem("adminAccess", res.data.user_type);
           localStorage.setItem("subadminAccess", res.data.sub_user_type);
          console.log("successlogin data ", data);
          try {
            // Save token in local storage
            localStorage.setItem("token", res.headers.token);
             
          } catch (e) {
            console.log(e);
          }

          self.loginForm.submitting = false;
          /**
           * Redirect to specific user panel
           */
          console.log("*******",authToken.decodedToken(), "DECODE");
         console.log("*******user_id",authToken.decodedToken().user_id); 
          localStorage.setItem("user_id", authToken.decodedToken().user_id);
          let userTeam = authToken.decodedToken().user_team
          if(userTeam ==="teacher"){
            userTeam = authToken.decodedToken().user_type
          }
          if (authToken.loginForFirstTime() === true) {
            self.showToast({ message: 'Logged In', color: 's' })
            // Redirect to admin panel
            self.$router.push(
              Helper.getUserPanelUrl(userTeam)
            );
          } else {
            self.showToast({ message: 'Set new password', color: 's' })
            self.$router.push({ name: ROUTER_URL.auth.children.resetPassword.name,
              query: { token: res.headers.token }})
          }
        };
        /**
         * Failure handler of API call
         */
        const failureHandler = (res) => {
          console.log("Failure ", res);
          self.loginForm.submitting = false;
        };
        let formData = {};
        formData[Keys.USER_EMAIL] = this.loginForm.identifier.trim();
        formData[Keys.PASSWORD] = this.loginForm.password;

        /**
         * API Call for login
         */
        Axios.request_POST(
          API_COMMON_URL.login,
          formData,
          {},
          successHandler,
          failureHandler,
          false,
          false
        );
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.heading-text {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  color: #2c2c2c;
}
.subHeading-text {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.6);
}
.textField-LoginField {
  z-index: 99;
}
.textField-LoginField >>> .v-input__slot {
  background-color: #f7f7f7 !important;
  /* border: 1px solid; */
  border-color: #c1bbbb;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}

.textField-LoginField >>> input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f7f7f7 inset;
}

.textField-LoginField >>> .v-label {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  color: #afafaf;
}
.login-btn {
  z-index: 99;
}
.login-btn >>> .text-loginBtn {
  text-transform: none;
  letter-spacing: 0ch;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 161.5%;
  color: #ffffff;
}
.forgotPassword-redirect {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
}
.forgotPassword-redirect >>> .resetNow-btn {
  z-index: 99;
  letter-spacing: 0px;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  color: #d30024;
  font-weight: bold;
  text-decoration: underline;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .heading-text {
    font-size: 25px;
    line-height: 35px;
  }
  .subHeading-text {
    font-size: 18px;
    line-height: 20px;
  }
  .textField-LoginField >>> .v-label {
    font-size: 18px;
  }
  .login-btn >>> .text-loginBtn {
    font-size: 20px;
    line-height: 161.5%;
  }
  .forgotPassword-redirect {
    font-size: 16px;
  }
  .forgotPassword-redirect >>> .resetNow-btn {
    font-size: 16px;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1904px) {
  .heading-text {
    font-size: 36px;
    line-height: 43px;
  }
  .subHeading-text {
    font-size: 22px;
    line-height: 26px;
  }
  .textField-LoginField >>> .v-label {
    font-size: 18px;
  }
  .login-btn >>> .text-loginBtn {
    font-size: 20px;
    line-height: 161.5%;
  }
  .forgotPassword-redirect {
    font-size: 18px;
  }
  .forgotPassword-redirect >>> .resetNow-btn {
    font-size: 18px;
  }
}
</style>
